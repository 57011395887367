/* SUPREMACY */
@font-face {
	font-family: "Share Tech";
	src: url("./assets/fonts/share-tech/share-tech.otf");
}
@font-face {
	font-family: "Nostromo Regular Medium";
	src: url("./assets/fonts/nostromo-regular/NostromoRegular-Medium.otf");
}
@font-face {
	font-family: "Nostromo Regular Black";
	src: url("./assets/fonts/nostromo-regular/NostromoRegular-Black.otf");
}
@font-face {
	font-family: "Nostromo Regular Heavy";
	src: url("./assets/fonts/nostromo-regular/NostromoRegular-Heavy.otf");
}
@font-face {
	font-family: "Nostromo Regular Bold";
	src: url("./assets/fonts/nostromo-regular/NostromoRegular-Bold.otf");
}

/* PASSPORT */
@font-face {
	font-family: "bizmoblack";
	src: url("./assets/fonts/Bizmo/bizmo-black-webfont.woff2") format("woff2"), url("./assets/fonts/Bizmo/bizmo-black-webfont.woff") format("woff");
	font-weight: normal;
	font-style: normal;
}
@font-face {
	font-family: "bizmobold";
	src: url("./assets/fonts/Bizmo/bizmo-bold-webfont.woff2") format("woff2"), url("./assets/fonts/Bizmo/bizmo-bold-webfont.woff") format("woff");
	font-weight: normal;
	font-style: normal;
}
@font-face {
	font-family: "bizmoextra_bold";
	src: url("./assets/fonts/Bizmo/bizmo-extrabold-webfont.woff2") format("woff2"),
		url("./assets/fonts/Bizmo/bizmo-extrabold-webfont.woff") format("woff");
	font-weight: normal;
	font-style: normal;
}
@font-face {
	font-family: "bizmoextra_light";
	src: url("./assets/fonts/Bizmo/bizmo-extralight-webfont.woff2") format("woff2"),
		url("./assets/fonts/Bizmo/bizmo-extralight-webfont.woff") format("woff");
	font-weight: normal;
	font-style: normal;
}
@font-face {
	font-family: "bizmolight";
	src: url("./assets/fonts/Bizmo/bizmo-light-webfont.woff2") format("woff2"), url("./assets/fonts/Bizmo/bizmo-light-webfont.woff") format("woff");
	font-weight: normal;
	font-style: normal;
}

@font-face {
	font-family: "bizmomedium";
	src: url("./assets/fonts/Bizmo/bizmo-medium-webfont.woff2") format("woff2"), url("./assets/fonts/Bizmo/bizmo-medium-webfont.woff") format("woff");
	font-weight: normal;
	font-style: normal;
}

@font-face {
	font-family: "bizmosemi_bold";
	src: url("./assets/fonts/Bizmo/bizmo-semibold-webfont.woff2") format("woff2"),
		url("./assets/fonts/Bizmo/bizmo-semibold-webfont.woff") format("woff");
	font-weight: normal;
	font-style: normal;
}

@font-face {
	font-family: "bizmothin";
	src: url("./assets/fonts/Bizmo/bizmo-thin-webfont.woff2") format("woff2"), url("./assets/fonts/Bizmo/bizmo-thin-webfont.woff") format("woff");
	font-weight: normal;
	font-style: normal;
}

@font-face {
	font-family: "bizmoregular";
	src: url("./assets/fonts/Bizmo/bizmo-regular-webfont.woff2") format("woff2"),
		url("./assets/fonts/Bizmo/bizmo-regular-webfont.woff") format("woff");
	font-weight: normal;
	font-style: normal;
}
